import { useState } from 'react';
import * as styles from './search.module.scss';
import searchBlack from '../../assets/icons/search-black.svg';

function Search() {
  const [value, setValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `/search?value=${value}`;
  };
  return (
    <div className={styles.searchContainer}>
      <div className={styles.searchWrap}>
        <form className={styles.searchForm} onSubmit={handleSubmit}>
          <label htmlFor="search">
            <input
              className={styles.searchInput}
              type="search"
              placeholder="Search…"
              id="search"
              onChange={(e) => setValue(e.target.value)}

            />
          </label>
          <button className={styles.searchButton} type="button">
            <img src={searchBlack} alt="like" />
          </button>
        </form>
      </div>
    </div>
  );
}

export { Search };
