import { bool, node } from 'prop-types';
import { Header, HeaderMobile } from './header';
import { Footer } from './footer';
import { TopHeaderBar } from './common/top-header-bar';
import * as styles from './layout.module.scss';
import { ChatBubble } from './chat-bubble';

function Layout({ children, topBar }) {
  return (
    <div className={styles.container}>
      {topBar && <TopHeaderBar />}
      <div className={styles.mobile}>
        <HeaderMobile />
      </div>
      <div className={styles.desktop}>
        <Header />
      </div>
      {children}
      <ChatBubble />
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: node.isRequired,
  topBar: bool,
};

Layout.defaultProps = {
  topBar: false,
};

export default Layout;
