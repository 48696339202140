import { Link } from 'gatsby';
import * as styles from './footer-menu.module.scss';
import { SocialIcons } from '../../socials-icons';
import { Wrap } from '../../sections-wrap';

function Menu() {
  return (
    <div className={styles.footerMenu}>
      <Wrap>
        <div className={styles.menuContainer}>
          <ul>
            <li>
              <Link className={styles.item} to="/about/">About</Link>
            </li>
            <li>
              <Link className={styles.item} to="/team/">Our Team</Link>
            </li>
            <li>
              <Link className={styles.item} to="/practice-areas/">Practice Areas</Link>
            </li>
            <li>
              <Link className={styles.item} to="/resource-center/">Resources</Link>
            </li>
            <li>
              <Link className={styles.item} to="/locations/">Contact</Link>
            </li>
            <li>
              <SocialIcons />
            </li>
          </ul>
        </div>
      </Wrap>
    </div>
  );
}

export { Menu };
