import { navigate } from 'gatsby';
import retry from 'async-await-retry';
import { constants } from '../config';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov',
  'Dec'];
const monthNamesComplete = constants.months;

const LPClassName = 'LPMcontainer LPMoverlay';
const appleUrl = `https://bcrw.apple.com/urn:biz:${process.env.GATSBY_APPLE_BUSINESS_ID}`;
const googleUrl = `https://businessmessages.google.com/widget/agent/${process.env.GATSBY_GOOGLE_AGENT_ID}?i=CgozOTMxNDU2MzM4EgsIxZLpogYQgIvWbRoCdjEqLQoraHR0cHM6Ly93d3cucm9iaW5zb25hbmRoZW5yeS5jb20vbG9jYXRpb25zLw&ctx=`;

const classNames = (classes) => {
  let ret = [];

  if (Array.isArray(classes)) {
    ret = classes.map(classNames);
  } else if (typeof classes === 'object') {
    Object.entries(classes).forEach(([key, value]) => {
      if (value) {
        ret.push(key);
      }
    });
  } else {
    return classes;
  }

  return ret.join(' ');
};

const toUrlEmbed = (url) => {
  const urlSplitted = url.split('/');
  const urlId = urlSplitted[urlSplitted.length - 1];
  const newUrl = `//player.vimeo.com/video/${urlId}`;
  return newUrl;
};

const onKeyDownNumberValidation = (evt) => {
  const { key } = evt;
  // Using isNaN() function instead of Number.isNaN()
  // will ensure consistent behavior across all browsers.
  const isNumber = !isNaN(key);
  const isBackspace = key === 'Backspace';
  const isDot = key === '.';
  const isSign = key === '+' || key === '-';
  const isExponent = key === 'e';

  if ((!isNumber && !isBackspace) || isDot || isSign || isExponent) {
    evt.preventDefault();
  }
};

const convertDate = (date) => {
  const d = new Date(date);
  if (d instanceof Date) {
    const month = d.getMonth();
    const day = d.getDate();
    const year = d.getFullYear();
    return ([monthNames[month], `${day},`, year].join(' '));
  }
  return '';
};

const convertDateComplete = (date) => {
  const d = new Date(date);
  if (d instanceof Date) {
    const month = d.getMonth();
    const day = d.getDate();
    const year = d.getFullYear();
    return ([monthNamesComplete[month], `${day},`, year].join(' '));
  }
  return '';
};

const convertDateCompleteWithoutDay = (date) => {
  const d = new Date(date);
  if (d instanceof Date) {
    const month = d.getMonth();
    const year = d.getFullYear();
    return ([monthNamesComplete[month], year].join(', '));
  }
  return '';
};

const plusYearsToADate = (date, plusYears) => {
  if (date instanceof Date && Number.isInteger(plusYears)) {
    const newDate = new Date(date.getTime());
    newDate.setFullYear(date.getFullYear() + plusYears);
    return newDate;
  }
  return date;
};

// the difference to years, rounding down
const calculateYearsPassed = (date, sndDate = new Date()) => {
  const difference = sndDate - date;
  return Math.floor(difference / (1000 * 60 * 60 * 24 * 365.25));
};

const calculateTimeReading = (text) => {
  let textWithoutTags = text.replace(/<script.*?<\/script>/gs, '');
  textWithoutTags = textWithoutTags.replace(/<.*?>/g, ' ');
  textWithoutTags = textWithoutTags.replace(/<\s+>/g, ' ');
  textWithoutTags = textWithoutTags.replace(/\n/g, '');
  const numWords = (textWithoutTags.split(' ').filter((word) => word !== '')).length;
  const averageReadingSpeedPerMinute = 300;
  const ret = Math.round(numWords / averageReadingSpeedPerMinute);
  return ret > 0 ? ret : 1;
};

const isInArray = (list, value) => {
  const index = list.findIndex((item) => item.toLowerCase() === value.toLowerCase());
  if (index === -1) {
    return false;
  }
  return true;
};

const addNotDuplicatedArrayString = (list, value) => {
  if (value) {
    const index = list.findIndex((item) => item.toLowerCase() === value.toLowerCase());
    const newList = [...list];
    if (index === -1) {
      newList.push(value);
    }
    return newList;
  }
  return list;
};

/* To avoid performance problems, we should run this function only on the 10 currently being
* displayed questions, not all of them */
const extractQuestion = (threadString) => {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) {
    const parser = new DOMParser();
    const thread = parser?.parseFromString(threadString, 'text/html');
    /* The question is always the first p */
    const pTags = thread?.getElementsByTagName('p');
    return pTags?.length > 0 ? pTags[0]?.textContent : null;
  }
  return '';
};

const blogPostsFiltered = (posts) => posts?.filter(
  (item) => !(item.categories?.nodes.filter(
    (node) => [process.env.GATSBY_EA_TOOLS_CATEGORY_ID,
      process.env.GATSBY_EA_QANDA_CATEGORY_ID,
      process.env.GATSBY_EA_QUIZZES_CATEGORY_ID,
      process.env.GATSBY_EA_CHECKLISTS_CATEGORY_ID,
      process.env.GATSBY_EA_CALCULATORS_CATEGORY_ID,
      process.env.GATSBY_EA_REVIEWS_CATEGORY_ID].includes(node.databaseId.toString()),
  ).length),
);

/* Using an object to make it more performant, so we avoid going through the tags array each
* time we try to add a new tag */
const getTagsFromPosts = (posts) => {
  const tags = {};
  if (posts && posts.length > 0) {
    posts.forEach((post) => {
      post.tags.nodes.forEach((tag) => {
        if (!tags[tag.name]) {
          tags[tag.name] = tag.uri;
        }
      });
    });
  }
  /* Finally we only go through the tags once to match the same structure in the Browse object */
  const tagsRet = Object.keys(tags).map((key) => ({ name: key, uri: tags[key] }));
  const tagsSorted = tagsRet?.sort((a, b) => (a.name?.localeCompare(b.name)));
  return tagsSorted;
};

const getPostAuthor = (schemaMarkup, publishDate) => {
  if (schemaMarkup?.eaPostSchemaAttorneyName
    && schemaMarkup?.eaPostSchemaAttorneyBioUrl) {
    return schemaMarkup.eaPostSchemaAttorneyName;
  } if (new Date(publishDate) > new Date(constants.texasDate)) {
    return constants.default2023Author;
  }
  return constants.defaultPre2023Author;
};

const getPostAuthorUrl = (author, attorneyUrl) => {
  if (author === constants.default2023Author) {
    /* Until defined a new url */
    return process.env.GATSBY_SITE_URL;
  } if (author === constants.defaultPre2023Author) {
    return constants.defaultPre2023AuthorUrl;
  }
  return attorneyUrl;
};

const createPostsJSONLD = (seo, siteUrl) => {
  const ogImage = seo?.opengraphImage;
  const { schemaMarkup } = seo;
  const author = getPostAuthor(schemaMarkup, seo.opengraphPublishedTime);
  const authorUrl = getPostAuthorUrl(author, schemaMarkup?.eaPostSchemaAttorneyBioUrl);

  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    genre: schemaMarkup.eaPostSchemaGenre,
    headline: seo.title,
    image: ogImage ? ogImage.sourceUrl : '',
    keywords: seo.tags,
    wordCount: schemaMarkup.eaPostSchemaWordCount,
    url: siteUrl + seo.opengraphUrl,
    datePublished: seo.opengraphPublishedTime,
    dateModified: seo.opengraphModifiedTime,
    description: seo.opengraphDescription,
    author: {
      '@type': 'Person',
      name: author,
      url: siteUrl + authorUrl,
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': siteUrl + seo.opengraphUrl,
    },
    articleBody: schemaMarkup.eaPostSchemaArticleBody,
  });
};

const createPostBreadCrumb = (seo, siteUrl) => {
  const lastBreadCrumb = seo.breadcrumbs.length > 1 ? seo.breadcrumbs[1] : null;
  const lastPosition = seo.missingBreadcrumb ? 4 : 3;
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Robinson & Henry',
        item: siteUrl,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Practice Areas',
        item: `${siteUrl}/practice-areas`,
      },
      ...seo.missingBreadcrumb ? [{
        '@type': 'ListItem',
        position: 3,
        name: seo.missingBreadcrumb.name,
        item: siteUrl + seo.missingBreadcrumb.link,
      }] : [],
      lastBreadCrumb && {
        '@type': 'ListItem',
        position: lastPosition,
        name: lastBreadCrumb.text,
        item: siteUrl + lastBreadCrumb.url,
      },
    ],
  });
};

const getPostMissingBreadcrumb = (post) => {
  let parentBreadcrumb = null;
  post.categories.nodes.forEach((category) => {
    if (category.wpParent && category.wpParent.node.name === 'Colorado') {
      parentBreadcrumb = {
        name: category.name,
        link: category.uri,
      };
    }
  });
  return parentBreadcrumb;
};

const createVideoSchema = (seo) => {
  const { schemaMarkup } = seo;
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Video',
    name: schemaMarkup.eaPostSchemaVideoName,
    description: schemaMarkup.eaPostSchemaVideoDescription,
    uploadDate: schemaMarkup.eaPostSchemaUploadDate,
    contentUrl: schemaMarkup.eaPostSchemaVideoUrl,
    duration: schemaMarkup.eaPostSchemaDuration,
    thumbnailUrl: [schemaMarkup.eaPostSchemaVideoUrl],
  });
};

const areBlogPostsInBothCountries = (posts) => {
  const coloradoBlogPosts = posts?.filter(
    (item) => item.categories?.nodes.filter(
      (node) => node.wpParent?.node.name === 'Colorado' || node.wpParent?.node.wpParent?.node.name === 'Colorado',
    ).length,
  );
  const texasBlogPosts = posts?.filter(
    (item) => item.categories?.nodes.filter(
      (node) => node.wpParent?.node.name === 'Texas' || node.wpParent?.node.wpParent?.node.name === 'Texas',
    ).length,
  );
  if (!!coloradoBlogPosts.length && !!texasBlogPosts.length) {
    return true;
  }
  return false;
};
const mapImageObject = (url, description) => ({
  '@type': 'ImageObject',
  caption: description,
  contentUrl: url,
});

const mapLocations = (location) => ({
  '@type': 'PostalAddress',
  addressLocality: location.addressLocality,
  addressRegion: location.addressRegion,
  streetAddress: location.streetAddress,
  postalCode: location.postalCode,
  telephone: location.telephone,
  description: location.locationTitle,
  ...location.locationImages && location.locationImages.length > 0
  && {
    image:
      mapImageObject(location.locationImages[0]?.url, location.locationImages[0]?.description),
  },
});

const createSiteWideSchema = (schema, siteUrl) => JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'LegalService',
  url: siteUrl,
  legalName: schema.legalName,
  name: schema.name,
  foundingDate: schema.foundingDate,
  currenciesAccepted: schema.currenciesAccepted,
  openingHours: schema.openingHours,
  priceRange: schema.priceRange,
  areaServed: schema.areaServed,
  telephone: schema.telephone,
  email: schema.email,
  numberOfEmployees: schema.numberOfEmployees,
  address: schema.locations.map((location) => mapLocations(location)),
  ...schema.logo && { logo: mapImageObject(schema.logo.url, schema.logo.description) },
  ...schema.image && { image: mapImageObject(schema.image.url, schema.image.description) },
});

const isWindowDefined = typeof window !== 'undefined';

const getQueryParams = () => {
  if (isWindowDefined) {
    const queryString = window.location.search;
    return new URLSearchParams(queryString);
  }
  return null;
};

const setQueryParams = (param, value) => {
  if (isWindowDefined) {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.set(param, value);
    const newUrl = `${url.origin}${url.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  }
};

const translateLocation = (location) => {
  switch (location) {
    case 'Texas':
      return 'TX';
    case 'Colorado':
      return 'CO';
    case 'All states':
      return 'Any';
    default:
      return '';
  }
};

const translateShortLocation = (location) => {
  switch (location) {
    case 'TX':
      return 'Texas';
    case 'CO':
      return 'Colorado';
    case 'Any':
      return 'All states';
    default:
      return '';
  }
};

const addOrRemoveFromArrayString = (value, list) => {
  const index = list.findIndex((item) => item.toLowerCase() === value.toLowerCase());
  const newList = [...list];
  if (index > -1) {
    newList.splice(index, 1);
  } else {
    newList.push(value);
  }
  return newList;
};

const getLocalStorageItem = (key) => {
  if (isWindowDefined) {
    return localStorage.getItem(key);
  }
  return null;
};

const setLocalStorageItem = (key, value) => {
  if (isWindowDefined) {
    localStorage.setItem(key, value);
  }
};

// eslint-disable-next-line no-restricted-globals
const isDate = (date) => !isNaN(new Date(date));

const getClosestDay = (fstDate, sndDate) => {
  let closestDay = '';
  if (isDate(fstDate) && isDate(sndDate)) {
    if (fstDate > sndDate) {
      closestDay = fstDate;
    } else {
      closestDay = sndDate;
    }
  } else {
    closestDay = isDate(sndDate) ? sndDate : fstDate;
  }
  return closestDay;
};

function makeBold(text, words) {
  const regex = new RegExp(`(${words.join('|')})`, 'gi');
  const chunks = text?.split(regex).map((chunk) => {
    if (words?.includes(chunk.toLowerCase())) {
      return <strong>{chunk}</strong>;
    }
    return chunk;
  });
  return chunks || '';
}

const convertToCurrencyValue = (value) => {
  let valueConverted = parseInt(value, 10) || 0;
  valueConverted = valueConverted.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  return valueConverted;
};

const convertCurrencyToValue = (value) => {
  const formattedValue = value;
  const numericValue = Number(formattedValue?.replace(/[^0-9.-]+/g, ''));
  return numericValue || 0;
};

const isNumberInValidRange = (number, minValue, maxValue) => {
  const min = minValue || 0;
  const max = maxValue || Number.MAX_VALUE;
  return (number || number === 0) && number >= min && number <= max;
};

const removeItemLocalStorage = (item) => {
  if (isWindowDefined) localStorage.removeItem(item);
};

const refreshSite = (item) => {
  removeItemLocalStorage(item);
  if (isWindowDefined) window.location.reload();
};

const monthsToYears = (durationOfMarriage) => {
  const years = Math.floor(durationOfMarriage / 12);
  const months = durationOfMarriage % 12;
  let wordYear = '';
  let wordMonth = '';

  if (years <= 1) {
    wordYear = 'year';
  } else {
    wordYear = 'years';
  }

  if (months <= 1) {
    wordMonth = 'month';
  } else {
    wordMonth = 'months';
  }

  if (years === 0) {
    return `${months} ${wordMonth}`;
  }
  if (months === 0) {
    return `${years} ${wordYear}`;
  }
  return `${years} ${wordYear} and ${months} ${wordMonth}`;
};

const isLettersOnly = (input) => {
  const regex = /^[A-Za-z]*$/;
  return regex.test(input);
};

const saveStateInHistory = (state) => {
  const newState = { ...window.history.state, ...state };
  window.history.replaceState(newState, '');
};

const navigateWithState = (path, state) => {
  const newState = { ...window.history.state, state };
  navigate(path, newState);
};

const monthsToYearsCard = (duration) => {
  const years = Math.floor(duration / 12);
  const months = duration % 12;

  return {
    years,
    months,
  };
};

const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod|Mac/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return 'iOS';
  }
  return 'Other';
};

const retryButton = async () => new Promise((resolve, reject) => {
  const chatLP = document.getElementsByClassName(LPClassName);
  if (chatLP) {
    resolve(chatLP[0].getElementsByTagName('a')[0]);
  } else {
    reject();
  }
});

const openChatLP = async (successLPFn = () => {}, failLPFn = () => {}, fetchingFn = () => {}) => {
  if (isWindowDefined) {
    const agent = getMobileOS();
    if (agent === 'Android') {
      window.open(googleUrl);
      successLPFn();
    } else if (agent === 'iOS') {
      window.open(appleUrl);
      successLPFn();
    } else if (!window.location.href.includes('location')) {
      navigate('/locations');
    } else {
    /* This is the case we used to have, where we wait for the LP chat to show up, and when it
    does, we click on it */
      try {
        fetchingFn();
        const chat = await retry(retryButton, null, { retriesMax: 4, interval: 2000 });
        chat.click();
        successLPFn();
      } catch (err) {
        failLPFn();
        console.error('Chat not initialized', err);
      }
    }
  }
};

const removingWordFree = (text) => {
  if (typeof text !== 'string') {
    return text;
  }
  return text
    .replace(/<em>\s*free<\/em>/gi, 'free')
    .replace(/<em>\s*free\s*<\/em>/gi, 'free ')
    .replace(/<span style="font-weight:\s*\d{3,};">\s*free<\/span>/gi, 'free')
    .replace(/<span style="font-weight:\s*\d{3,};">\s*free\s*<\/span>/gi, 'free ')
    .replace(/<i>\s*free<\/i>/gi, 'free')
    .replace(/<i>\s*free\s*<\/i>/gi, 'free ')
    .replace(/free 30/gi, '30')
    .replace(/free one-hour/gi, 'one-hour')
    .replace(/free, 30-minute/gi, '30-minute')
    .replace(/free no obligation/gi, '')
    .replace(/free no-obligation/gi, '')
    .replace(/free, no obligation/gi, '')
    .replace(/free, no-obligation/gi, '')
    .replace(/Free Case/g, 'Case')
    .replace(/free case/gi, 'case')
    .replace(/Free Divorce Case/g, 'Divorce Case')
    .replace(/Free Divorce Case/gi, 'divorce case')
    .replace(/Free Criminal Law Case/g, 'Criminal Law Case')
    .replace(/Free Criminal Law Case/gi, 'criminal law case')
    .replace(/free\s*case/gi, 'case')
    .replace(/free, case/gi, 'case')
    .replace(/Free Consultation/g, 'Consultation')
    .replace(/free consultation/gi, 'consultation')
    .replace(/Free Consult/g, 'Consult')
    .replace(/free consult/gi, 'consult')
    .replace(/Free Initial/g, 'Initial')
    .replace(/free initial/gi, 'initial')
    .replace(/free, initial/gi, 'initial')
    .replace(/Free Meeting/g, 'Meeting')
    .replace(/free meeting/gi, 'meeting')
    .replace(/Free Assessment/g, 'Assessment')
    .replace(/free assessment/gi, 'assessment')
    .replace(/Complimentary Consultation/g, 'Consultation')
    .replace(/complimentary consultation/gi, 'consultation')
    .replace(/Free Appointment/g, 'Appointment')
    .replace(/free appointment/gi, 'appointment')
    .replace(/Free Legal/g, 'Legal')
    .replace(/free legal/gi, 'legal')
    .replace(/free personal assessment/gi, 'personal assessment')
    .replace(/free bankruptcy case/gi, 'bankruptcy case')
    .replace(/attorney for free/gi, 'attorney')
    .replace(/offers free, confidential/gi, 'offers confidential')
    .replace(/free immigration case/gi, 'immigration case')
    .replace(/free insurance claim denial/gi, 'insurance claim denial');
};

function capitalizeEveryWord(input) {
  const words = input.split(' ');

  const capitalizedWords = words.map((word) => {
    if (word.length === 0) {
      return word; // Handle empty strings or spaces.
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return capitalizedWords.join(' ');
}

const articleDate = (date, dateModified, videoName, returnCompleteDate = false) => {
  const dateFormat = new Date(date).toISOString();
  if (!videoName && !!dateModified) {
    const [day, month, year] = dateModified.split('/');
    const dateModifiedFormat = new Date(+year, +month - 1, +day).toISOString();
    if (dateModifiedFormat <= dateFormat) {
      return returnCompleteDate ? convertDateComplete(date) : convertDate(date);
    }
    return returnCompleteDate
      ? convertDateComplete(dateModifiedFormat) : convertDateComplete(dateModifiedFormat);
  }
  return returnCompleteDate ? convertDateComplete(date) : convertDate(date);
};

const dateToCompareArticles = (date, dateModified, videoName) => {
  const dateFormat = new Date(date).toISOString();
  if (!videoName && !!dateModified) {
    const [day, month, year] = dateModified.split('/');
    const dateModifiedFormat = new Date(+year, +month - 1, +day).toISOString();
    if (dateModifiedFormat <= dateFormat) {
      return dateFormat;
    }
    return dateModifiedFormat;
  }
  return dateFormat;
};

const sortArticlesByDate = (articles) => {
  const ret = articles?.sort(
    (b, a) => {
      const fstArticleDate = a.date;
      const fstArticleModified = a.schemaMarkup.eaPostSchemaUploadDate;
      const fstArticleVideoName = a.schemaMarkup.eaPostSchemaVideoName;
      const sndArticleDate = b.date;
      const sndArticleModified = b.schemaMarkup.eaPostSchemaUploadDate;
      const sndArticleVideoName = b.schemaMarkup.eaPostSchemaVideoName;
      return dateToCompareArticles(fstArticleDate, fstArticleModified, fstArticleVideoName)
      <= dateToCompareArticles(sndArticleDate, sndArticleModified, sndArticleVideoName)
        ? -1 : 1;
    },
  );
  return ret;
};

export {
  classNames, toUrlEmbed, convertDate, convertDateComplete, calculateYearsPassed, extractQuestion,
  addNotDuplicatedArrayString, isInArray, blogPostsFiltered, createPostsJSONLD,
  createPostBreadCrumb, getPostMissingBreadcrumb, getTagsFromPosts, createVideoSchema,
  areBlogPostsInBothCountries, getPostAuthor, createSiteWideSchema,
  addOrRemoveFromArrayString, onKeyDownNumberValidation, getLocalStorageItem, setLocalStorageItem,
  isDate, getClosestDay, getQueryParams, makeBold, convertToCurrencyValue, convertCurrencyToValue,
  removeItemLocalStorage, refreshSite, monthsToYears, translateShortLocation, translateLocation,
  monthsToYearsCard, isLettersOnly, isWindowDefined, saveStateInHistory, navigateWithState,
  setQueryParams, getMobileOS, openChatLP, convertDateCompleteWithoutDay, plusYearsToADate,
  isNumberInValidRange, calculateTimeReading, removingWordFree, capitalizeEveryWord, articleDate,
  sortArticlesByDate,
};
