import { func } from 'prop-types';
import closeMenu from '../../../assets/icons/close-menu.svg';
import { Item } from './item';

import * as styles from './header.module.scss';

function Menu({ seeMenuFn }) {
  return (
    <div className={styles.menuOverlay}>
      <button type="button" className={styles.button} onClick={() => seeMenuFn(false)}>
        <img src={closeMenu} alt="close menu" />
      </button>
      <div className={styles.menuContainer}>
        <Item
          closeMenuFn={() => seeMenuFn(false)}
          text="ABOUT"
          to="/about/"
          subMenu={[
            { name: 'About Us', uri: '/about/' },
            { name: 'Philosophy', uri: '/about/philosophy/' },
            { name: 'Careers', uri: '/about/careers/' },
            { name: 'Resources', uri: '/resource-center/' },
            { name: 'Ask a lawyer', uri: '/questions/' },
            { name: 'Media', uri: '/media/' },
          ]}
        />
        <Item closeMenuFn={() => seeMenuFn(false)} text="PRACTICE AREAS" to="/practice-areas/" />
        <Item closeMenuFn={() => seeMenuFn(false)} text="OUR TEAM" to="/team/" />
        <Item closeMenuFn={() => seeMenuFn(false)} text="CASE RESULTS" to="/case-outcomes/" />
        <Item closeMenuFn={() => seeMenuFn(false)} text="CONTACT" to="/locations/" />
      </div>
    </div>
  );
}

Menu.propTypes = {
  seeMenuFn: func.isRequired,
};

export { Menu };
