import Layout from './src/components/layout';
import { TrackingScript } from './src/components/tracking-script/tracking-script';
import { TrackingScriptBing } from './src/components/tracking-script-bing/tracking-script-bing';

export const wrapPageElement = ({ element }) => (
  <Layout>
    {TrackingScript()}
    {TrackingScriptBing()}
    {element}
  </Layout>
);
