import { convertDateComplete } from '../helpers';
import { getLocalStorageItem, removeItemLocalStorage, convertToCurrencyValue, monthsToYears } from "./misc";
// Contentful Logic; Questions could have "[**insert**]" in it, in that case we would replace this
// string for a text loaded in contentful called 'textToInsert', this text could have strings
// like [-0-], [-1-], etc. that represent a responses of some previous question.
// Those previous questions are in 'questionsToGetAnswers' and we get those values from our
// object of responses
const replaceSpecialCharacterText = (text, textToInsert, questionsToGetAnswers, answersValues, defaultText) => {
  let ret = text;
  let defaultQuestion;
  if (text?.includes('[**insert**]')) {
    questionsToGetAnswers?.forEach((node) => {
      if(!answersValues.get(node.id).value) {
        defaultQuestion = text.replace('[**insert**]', defaultText);
      }
    });

    if (defaultQuestion) {
      return defaultQuestion;
    }

    ret = text.replace('[**insert**]', textToInsert);
    let index = 0;
    questionsToGetAnswers?.forEach((node) => {
      // TODO; Handle when value isn't get from answers and we have to use the default value
      // instead of 'error getting id'
      const replace = answersValues.get(node.id) ? answersValues.get(node.id).value : 'Error getting id';
      ret = ret.replace(`[-${index}-]`, replace);
      index += 1;
    });
  }
  return ret;
};

// Pre-condition: This function will be called if we detected that some calculator type was started.
// The redirect to the default one is just for caution
const moveToCalculator = (
  mainUri,
  calculatorTypes,
  calculatorTypeDefault,
  navigateFn,
) => {
  const isCalculatorStarted = calculatorTypes.find(
    (item) => getLocalStorageItem(item.name),
  )?.type ?? calculatorTypeDefault;
  navigateFn(`${mainUri}${isCalculatorStarted}`);
};

const startOverCalculator = (navigateFn, calculatorTypes, redirectTo, termsAccepted, calculatorFinished) => {
  calculatorTypes.map(
    (item) => removeItemLocalStorage(item.name),
  );
  removeItemLocalStorage(termsAccepted);
  removeItemLocalStorage(calculatorFinished);
  navigateFn(redirectTo);
};

// “Duration of marriage” means the number of whole months, beginning from the first day of the 
// month following the date of the parties’ marriage until the date of decree
const getDurationOfMarriage = (married, divorced) => {
  const dateMarried = new Date(married);
  const yearMarried = dateMarried.getFullYear();
  const monthMarried = dateMarried.getMonth();
  const dateDivorced = new Date(divorced);
  const yearDivorced = dateDivorced.getFullYear();
  const monthDivorced = dateDivorced.getMonth();
  const dateOfMarriage = ((yearDivorced - yearMarried) * 12) + monthDivorced - monthMarried;
  return dateOfMarriage > 0 ? dateOfMarriage : 0;
 };

const getDurationOfAlimony = (dateMarried, dateDivorced, alimonyDurationGuideline) => {
  let durationOfAlimony = 0;
  const durationOfMarriage = getDurationOfMarriage(dateMarried, dateDivorced)
  if(durationOfMarriage >= 36 && durationOfMarriage <= 240){
    durationOfAlimony = alimonyDurationGuideline?.find(
      (chart) => parseInt(chart.monthOfMarraige) === durationOfMarriage,
    );
    return durationOfAlimony?.suggestedGuideline;
  } else if (durationOfMarriage < 36){
    return 0;
  }else {
    return 0;
  }
};

// If the preexisting court-ordered alimony or maintenance obligations actually paid by a party are
// not deductible for federal income tax purposes by that party, then the amount of preexisting 
// court-ordered alimony or maintenance that is deducted from that party’s gross income is the 
// amount actually paid by that party multiplied by 1.33.
const getPreexistingAlimonyObligation = (hasPreexstingAlimonyObligations, isAlimonyDeductible, alimonyPaidAmount) => {
  if(hasPreexstingAlimonyObligations === 'yes'){
    return isAlimonyDeductible === 'yes' ? parseInt(alimonyPaidAmount) : parseInt(alimonyPaidAmount) * 1.33;
  }
  return 0;
};

// 
const childSupportGuidelineIncome = (income, amountOfChildren, childSupportAmount) => {
  let lookUpAmount = 0;
  if (income >= 1500 && income <= 30000) {
    if (income % 50 === 0) {
      const chartMatched = childSupportAmount?.find(
        (chart) => chart.monthlyIncome == income,
      );
      lookUpAmount = chartMatched[`_${amountOfChildren}`];
    } else {
      const leftToMod50 = income % 50;
      const IncomePrevChart = income - leftToMod50;
      const IncomeNextChart = income + 50 - leftToMod50;
      lookUpAmount = IncomePrevChart + ((1 - IncomeNextChart - income / 50)
                   * (IncomeNextChart - IncomePrevChart));
    }
  } else if (income > 650) {
    lookUpAmount = 50;
  } else {
    lookUpAmount = 10;
  }

  // Calculate the discount
  const adjustmentForChild = income > 1500 ? lookUpAmount * 0.75 : lookUpAmount * 0.8;
  return adjustmentForChild;
};

const getPreexistingChildSupportObligation = (children, income, childSupportAmount) => {
  if (children?.hasChildren) {
    let adjustmentForChild = 0;
    const dataChildren = children.children?.filter((child) => child.isDisabled || child.age < 19);

    const childrenLiveWithParent = dataChildren?.filter((child) => child.liveTogether);
    if (!!childrenLiveWithParent.length) {
      const childrenLiveWithParentLength = childrenLiveWithParent?.length > 6 ? 6 : childrenLiveWithParent?.length;
      adjustmentForChild += parseInt(childSupportGuidelineIncome(
        income,
        childrenLiveWithParentLength,
        childSupportAmount,
      ));
    }

    const childrenDontLiveWithParent = dataChildren?.filter((child) => !child.liveTogether);
    childrenDontLiveWithParent.forEach((child) => {
        if (child.court.payCourtOrdered) {
          adjustmentForChild += parseInt(child.court.amount);
        } else {
          adjustmentForChild += parseInt(child.amtPaid);
        }
    });

    return adjustmentForChild;
  }

  return 0;
};

const whoPay = (isUserPayor, userName = 'you', spouseName = 'your spouse', middleWord = 'pay') => {
  const payer = isUserPayor ? userName : spouseName;
  const payee = isUserPayor ? spouseName : userName;
  return `<b>${payer}</b> ${middleWord} <b>${payee}</b>`;
};

const getAlimonyPayment = (combinedMonthlyAGI, dateDivorced, isDeductible, amountOfAlimony, isUserPayor, userName, spouseName) => {
  const formattedDate = convertDateComplete(dateDivorced);
  const payor = whoPay(isUserPayor, userName || 'you', spouseName || 'your spouse', 'to');
  const wouldPayor = whoPay(isUserPayor, userName || 'you', spouseName || 'your spouse', 'would pay');
  const amountOfAlimonyCurrency = convertToCurrencyValue(amountOfAlimony);
  const combinedAnnualAGI = combinedMonthlyAGI * 12;
  const combinedAnnualAGICurrency = convertToCurrencyValue(combinedAnnualAGI)

  if(combinedAnnualAGI <= 240000){
    const deductible = isDeductible ? 'deductible' : 'not deductible';

    const message = `Since your divorce was finalized on ${formattedDate}, alimony payments are 
    <b>${deductible}</b> under federal tax law. Under the guidelines, the amount of alimony would
    be <b>${amountOfAlimonyCurrency}</b> per month paid by ${payor}.`;

    const advisoryMessage = `This amount is advisory, so the court is not required to order this
    amount. The court will consider many other factors as it decides on an alimony award that is
    fair and equitable to both parties based on the circumstances.`;

    return `${message}</br></br>${advisoryMessage}`;
  } 

  const message = `In Colorado, courts usually turn to statutory guidelines to determine alimony.
  The guidelines do not apply, however, if the parties’ combined income exceeds $240,000 per year. In this
  case, <b>${userName || 'you'}</b> and <b>${spouseName || 'your spouse' }</b> have a combined 
  income of ${combinedAnnualAGICurrency} per year, so the alimony award is up to the court.`;

  const extrapolationAlimony = `Although we do not know what the court would do, we can get an
  idea by extrapolating the statute. The statute stops providing recommendations to the court
  at a combined income of $240,000 per year. If we apply the same formula, ${wouldPayor} <b>${amountOfAlimonyCurrency}
  per month.</b>`;

  return `${message}</br></br>${extrapolationAlimony}`;
}

const displayDuration = (duration) => {
  return `${duration > 0 ? `${duration} months` : 'less than one month'}`;
}

const displayDurationConverted = (durationConverted) => {
  return `${!!durationConverted ? `, or ${durationConverted}.` : '.'}`
}


const displayAnswer = (durationOfMarriage, durationOfAlimony, dateDivorced, isDeductible, isUserPayor, userName, spouseName, combinedMonthlyAGI, amountOfAlimony) => {
  const payor = whoPay(isUserPayor, userName|| 'you' , spouseName || 'your spouse', 'to pay');
  const convertionDurationOfMarriage = monthsToYears(durationOfMarriage);
  const convertionDurationOfAlimony = monthsToYears(durationOfAlimony);
  let alimonyDuration = '';
  let alimonyMessage = '';
  const duratonOfMarriageToDisplay = displayDuration(durationOfMarriage);
  const convertionDurationOfMarriageToDisplay = displayDurationConverted(convertionDurationOfMarriage);
  const convertionDurationOfAlimonyToDisplay = displayDurationConverted(convertionDurationOfAlimony)
  if (durationOfMarriage < 36 ){
    const alimonyDurationWithoutGuideline = durationOfAlimony === 0 
    ? Math.round(durationOfMarriage * 0.31) : durationOfAlimony;

    const message = `In Colorado, courts turn to guidelines created by state lawmakers to determine if and 
    how much alimony should be awarded to a spouse. The guidelines recommend awarding alimony if
    a couple is married for 36 months or longer. Since the marriage lasted 
    <b>${duratonOfMarriageToDisplay}</b>, it would be unusual for the court to order
    ${payor} alimony.`;

    const advisoryMessage = `Nevertheless, the state’s alimony guidelines are merely
    recommendations. The court has the freedom to award alimony that deviates from 
    the guidelines, even in short-term marriages when dividing the marital property
    is not sufficient to be fair for both spouses.`

    const advisoryGuideline = `So, if the court was to award alimony in a marriage 
    of just <b>${duratonOfMarriageToDisplay}</b>, it can follow the guideline’s 
    formula to decide how much alimony should be paid and for how long.`

    const alimonyDurationMessage = `As mentioned, it is very unlikely that the court will award alimony with
    such a short marriage and the guidelines do not advise alimony payments in your case.
    Hypothetically, if the court did award alimony, the court may extrapolate from the closest
    statutory guideline. The statutory guidelines advise the court to award 11
    months of alimony, or 31% of the length of a three-year marriage. If we apply the same
    formula to your situation, since you were married for ${duratonOfMarriageToDisplay},
    the court may award alimony for <b>${alimonyDurationWithoutGuideline}</b> months. </br>
    Again, it is up to the discretion of the court.`

    alimonyDuration = `<b>Alimony Duration: </b></br>${alimonyDurationMessage}`
     
    alimonyMessage = `${message}</br></br>${advisoryMessage}</br></br>${advisoryGuideline}</br></br>${alimonyDuration}`; 
  } 

  else if (durationOfMarriage > 240 ){
    const halfDurationOfMarriage = Math.round(durationOfMarriage / 2);
    const userNameText = userName ? `${userName} was` : 'you were';
    const spouseNameText = spouseName ? spouseName : 'your spouse';
    const convertionHalfDurationOfMarriage = monthsToYears(halfDurationOfMarriage);
    const convertionDurationOfMarriage = monthsToYears(durationOfMarriage);

    const message = `Colorado law provides advisory guidelines for the duration of alimony as well as the
     amount of the payments in certain circumstances. In your case, you were married for
     ${duratonOfMarriageToDisplay}${convertionDurationOfMarriageToDisplay}`

    const marriageMessage = `Since your marriage exceeded 20 years, the advisory guidelines 
    instruct the court to award payments for at least 10 years, but the court has the 
    authority to order indefinite alimony. However, the statute prevents courts from issuing
    an alimony term that is less than what the guidelines recommend for a 20-year marriage
    unless circumstances warrant a shorter duration.`

    const alimonyDurationMessage = `As mentioned, the court has the power to enter a term of
    alimony from 10 years to lifetime alimony. Although we cannot predict exactly what the 
    court would do, we can get an idea by extrapolating information from the advisory guidelines.`

    const alimonyStatuteMessage = `The statute stops making recommendations to the court at a 
    20-year marriage. For couples married 20 years, the court is advised to award alimony for
    10 years or 50% of the length of the marriage. Since ${userNameText} 
    married to ${spouseNameText} for ${duratonOfMarriageToDisplay}, we could expect
    the court to award alimony for 50% of your marriage, which would be
    ${!!convertionHalfDurationOfMarriage ? `<b>${convertionHalfDurationOfMarriage}</b>` : `<b>${halfDurationOfMarriage}</b> months`}.
    Again, the court could reduce the alimony term under special circumstances, or it could
    order alimony payments indefinitely.`

    alimonyDuration = `<b>Alimony Duration: </b></br>${alimonyDurationMessage}</br></br>${alimonyStatuteMessage}`


    alimonyMessage = `${message}</br></br>${marriageMessage}</br></br>${alimonyDuration}`; 
  } 

  else {
    const message = `In Colorado, court-ordered alimony terms are calculated using a formula that
    takes into account how long a couple was married. The statutory alimony guidelines
    direct courts on marriages lasting between three and 20 years.`

    const alimonyDurationMessage = `Since you were married for ${durationOfMarriage} months, 
    the court will likely award alimony for ${durationOfAlimony} months<b>${convertionDurationOfAlimonyToDisplay}</b>` 

    const alimonyRecommendation = `We should note that this duration is a recommendation. 
    The court is not required to always adhere to the guidelines. A judge may issue an
    alimony term that’s shorter or longer than the guidelines based on a couple’s 
    circumstances. `
    
    alimonyDuration = `<b>Alimony Duration: </b></br>${alimonyDurationMessage}</br></br>${alimonyRecommendation}`

    alimonyMessage = `${message}</br></br>${alimonyDuration}`
  }

  const alimonyPaymentMessage = getAlimonyPayment(combinedMonthlyAGI, dateDivorced, isDeductible, amountOfAlimony, 
    isUserPayor, userName, spouseName);
  const alimonyPayment = `<b>Alimony Payment: </b></br>${alimonyPaymentMessage}`

  return `${alimonyMessage}</br></br>${alimonyPayment}`
};

export {
  replaceSpecialCharacterText, moveToCalculator, startOverCalculator, getDurationOfAlimony, 
  getPreexistingAlimonyObligation, getPreexistingChildSupportObligation, displayAnswer,
  getDurationOfMarriage,
};
