import { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import search from '../../../assets/icons/search.svg';
import { Item } from './item';
import { Search } from '../search';
import * as styles from './header.module.scss';

function Header() {
  const [seeSearch, setSeeSearch] = useState(false);
  const isSubMenuAbout = typeof window !== 'undefined' ? window.location.href.includes('about')
  || window.location.href.includes('resource-center') : false;

  return (
    <>
      <nav className={styles.headerContainer}>
        <div className={styles.itemsContainer}>
          <Link aria-label="Robinson and Henry" to="/">
            <StaticImage className={styles.image} src="../../../images/logo.png" alt="Robinson & Henry, P.C. logo" />
          </Link>
          <Item
            text="ABOUT"
            to="/about/"
            active={isSubMenuAbout}
            subMenu={[
              { name: 'About Us', uri: '/about/' },
              { name: 'Philosophy', uri: '/about/philosophy/' },
              { name: 'Careers', uri: '/about/careers/' },
              { name: 'Resources', uri: '/resource-center/' },
              { name: 'Ask a Lawyer', uri: '/questions/' },
              { name: 'Media', uri: '/media/' },
            ]}
          />
          <Item text="PRACTICE AREAS" to="/practice-areas/" />
          <Item text="OUR TEAM" to="/team/" />
          <Item text="CASE RESULTS" to="/case-outcomes/" />
          <Item text="CONTACT" to="/locations/" />
          <button type="button" onClick={() => setSeeSearch(!seeSearch)}>
            <img src={search} alt="like" />
          </button>
        </div>
      </nav>
      {seeSearch && (<Search />)}
    </>
  );
}

export { Header };
