import { node, string } from 'prop-types';
import { classNames } from '../../helpers';
import * as styles from './wrap.module.scss';

function Wrap({ children, className }) {
  return (
    <div className={classNames([styles.wrap, className])}>
      {children}
    </div>
  );
}

Wrap.propTypes = {
  children: node.isRequired,
  className: string,
};

Wrap.defaultProps = {
  className: '',
};

export { Wrap };
