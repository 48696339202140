import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import { Menu } from './footer-menu';
import { Wrap } from '../sections-wrap';
import * as styles from './footer.module.scss';

function Footer() {
  const queryFooter = useStaticQuery(graphql`
  {
    allWpLocation(sort: {fields: [databaseId], order: ASC}) {
      nodes {
        locationSingleConfiguration {
          eaLocationCity
          eaLocationHours
          eaLocationPhone
          eaLocationState
          eaLocationStreetAddress01
          eaLocationStreetAddress02
          eaLocationTextNumber
          eaLocationZip
        }
      }
    }
  }
`);

  const currentYear = new Date().getFullYear();
  const dataFooter = queryFooter?.allWpLocation?.nodes;
  return (
    <>
      <Menu />
      <footer className={styles.footer}>
        <Wrap>
          <div className={styles.locations}>Locations</div>
          <div className={styles.columns}>
            {dataFooter?.map((office) => (
              <div key={office.locationSingleConfiguration.eaLocationStreetAddress01}
                   className={styles.location}>
                <div>
                  {office.locationSingleConfiguration.eaLocationCity}
                  {' '}
                  Office
                  <br />
                  {office.locationSingleConfiguration.eaLocationStreetAddress01}
                  {', '}
                  {office.locationSingleConfiguration.eaLocationStreetAddress02}
                  <br />
                  {office.locationSingleConfiguration.eaLocationCity}
                  {', '}
                  {office.locationSingleConfiguration.eaLocationState}
                  {' '}
                  {office.locationSingleConfiguration.eaLocationZip}
                  <br />
                  <div
                    dangerouslySetInnerHTML={
                      { __html: office.locationSingleConfiguration.eaLocationHours }
                    }
                  />
                  <a href={`tel:${office.locationSingleConfiguration.eaLocationPhone}`}>
                    {office.locationSingleConfiguration.eaLocationPhone}
                  </a>
                </div>
              </div>
            ))}

            <div className={styles.links}>
              <p>
                <Link  to="/blog/" >Blog </Link>
                <br />
                <Link  to="/about/careers/" >Careers </Link>
                <br />
                <Link  to="/privacy/" >Privacy Policy </Link>
                <br />
                <Link  to="/policy-terms-conditions/" >Terms &amp; Conditions </Link>
                <br />
                <Link  to="/sms-disclaimer/" >Text (SMS) Disclaimer </Link>
                <br />
                <Link  to="/media-inquiries/" >Media Inquiries </Link>
              </p>
            </div>
          </div>
          <span className={styles.copyright}>
            © Copyright {currentYear}, Robinson &amp; Henry, P.C.
          </span>
        </Wrap>
      </footer>
    </>
  );
}

export { Footer };
