import { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import searchMobile from '../../../assets/icons/search-mobile.svg';
import menu from '../../../assets/icons/menu.svg';
import { Search } from '../search';
import { Menu } from './menu';
import * as styles from './header.module.scss';

function HeaderMobile() {
  const [seeSearch, setSeeSearch] = useState(false);
  const [seeMenu, setSeeMenu] = useState(false);
  return (
    <>
      <nav className={styles.headerContainerMobile}>
        <div className={styles.itemsContainer}>
          <Link aria-label="Robinson and Henry" to="/">
            <StaticImage className={styles.image} src="../../../images/logo.png" alt="Robinson & Henry, P.C. logo" />
          </Link>
          <div className={styles.buttonContainer}>
            <button className={styles.button} type="button" onClick={() => setSeeSearch(!seeSearch)}>
              <img src={searchMobile} alt="see search" />
            </button>
            <button className={styles.button} type="button" onClick={() => setSeeMenu(!seeMenu)}>
              <img src={menu} alt="see menu" />
            </button>
          </div>
        </div>
      </nav>
      {seeSearch && (<Search />)}
      {seeMenu && (<Menu seeMenuFn={setSeeMenu} />)}
    </>
  );
}

export { HeaderMobile };
