import { string } from 'prop-types';
import * as styles from './social-icons.module.scss';
import { classNames } from '../../helpers';

function SocialIcons({ className }) {
  return (
    <p className={classNames([styles.socials, className])}>
      <a
        aria-label="Linkedin"
        href="https://www.linkedin.com/company/robinson-&amp;-henry-p-c-"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
          <g>
            <path d="M5.26 7L1.36 7C1.12251756 7 .93 7.19251756.93 7.43L.93 20C.93 20.2374824 1.12251756 20.43 1.36 20.43L5.26 20.43C5.49748244 20.43 5.69 20.2374824 5.69 20L5.69 7.44C5.69269296 7.32423428 5.64858116 7.21227538 5.56764679 7.12945882 5.48671243 7.04664226 5.37579704 6.99996868 5.26 7zM7.76 20.47L11.5 20.47C11.7374824 20.47 11.93 20.2774824 11.93 20.04L11.93 13.43C11.93 12.34 12.27 11.03 13.88 11.03 14.3 11.03 15.58 11.03 15.58 13.43L15.58 20C15.58 20.2374824 15.7725176 20.43 16.01 20.43L19.89 20.43C20.1274824 20.43 20.32 20.2374824 20.32 20L20.32 12C20.32 8.6 18.45 6.56 15.32 6.56 14.0688579 6.5407196 12.859644 7.01077012 11.95 7.87L11.95 7.37C11.9191274 7.14836271 11.723425 6.98745191 11.5 7L7.76 7C7.52251756 7 7.33 7.19251756 7.33 7.43L7.33 20C7.31871278 20.1207992 7.35898251 20.2407453 7.44087879 20.3302598 7.52277506 20.4197744 7.63867574 20.4705262 7.76 20.47zM3.29 6.22C4.40317224 6.22404793 5.40898703 5.55660451 5.83778739 4.529327 6.26658774 3.5020495 6.03378417 2.31758903 5.24808239 1.52901973 4.46238062.740450428 3.2787761.503333439 2.2499433.9283886 1.2211105 1.35344376.55000736 2.3568204.55 3.47.549989984 4.98488637 1.77512364 6.21449132 3.29 6.22z" />
          </g>
        </svg>
      </a>
      {' '}
      <a
        aria-label="Twitter"
        href="https://twitter.com/robinsonhenry"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 21">
          <path d="M0.71,18.47 C2.97586984,19.9293848 5.61482723,20.7037104 8.31,20.7 C12.1163429,20.7765125 15.780248,19.2529084 18.41,16.5 C21.0305296,13.7326402 22.4659382,10.0508171 22.41,6.24 C23.3033203,5.55924041 24.0772102,4.73465911 24.7,3.8 C24.9209139,3.60670034 24.9432997,3.2709139 24.75,3.05 C24.5567003,2.8290861 24.2209139,2.80670034 24,3 C23.76,3.10666667 23.5133333,3.20333333 23.26,3.29 C23.6105969,2.82115969 23.8809377,2.29737429 24.06,1.74 C24.122163,1.53526998 24.0525788,1.31336071 23.8846458,1.180782 C23.7167128,1.04820328 23.4847132,1.03202014 23.3,1.14 C22.490485,1.60625126 21.6133135,1.9436249 20.7,2.14 C19.7147552,1.24606176 18.4303357,0.753700958 17.1,0.76 C15.606577,0.761522991 14.1831034,1.39304232 13.1797504,2.49920565 C12.1763975,3.60536897 11.6862924,5.08350661 11.83,6.57 C8.37256718,6.22242182 5.19553793,4.51337437 3,1.82 C2.89300246,1.68882356 2.72884986,1.61793948 2.56,1.63 C2.3887804,1.64363409 2.23533881,1.74093851 2.15,1.89 C1.11629373,3.67658316 1.21384618,5.90077913 2.4,7.59 L2.17,7.47 C2.01137363,7.38291718 1.81888221,7.38456166 1.6617668,7.47434189 C1.50465139,7.56412213 1.40550819,7.729126 1.4,7.91 C1.37592062,9.61062159 2.17252224,11.2187146 3.54,12.23 C3.38407869,12.2442245 3.24286615,12.3279464 3.15557024,12.4579203 C3.06827434,12.5878942 3.04418843,12.7502833 3.09,12.9 C3.6273396,14.5793453 4.96606999,15.8805762 6.66,16.37 C4.95731212,17.352705 2.98167599,17.7562541 1.03,17.52 C0.789923904,17.4917147 0.562017544,17.6326023 0.48,17.86 C0.40386842,18.0921162 0.499569399,18.3459318 0.71,18.47 Z" />
        </svg>
      </a>
      {' '}
      <a
        aria-label="Facebook"
        href="https://www.facebook.com/robinsonandhenry/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 28">
          <path d="M11.23,6.27 L15.61,6.27 L15.61,0.85 L11.23,0.85 C9.40920455,0.9253477 7.69294775,1.72104137 6.45890808,3.06198114 C5.22486841,4.4029209 4.57416814,6.17922465 4.65,8 L4.65,10.74 L0.27,10.74 L0.27,16.18 L4.65,16.18 L4.65,27.58 L10.13,27.58 L10.13,16.15 L15.61,16.15 L15.61,10.71 L10.13,10.71 L10.13,7.92 C10.13,7 10.71,6.27 11.23,6.27 Z" />
        </svg>
      </a>
      {' '}
      <a
        aria-label="Instagram"
        href="https://www.instagram.com/robinsonandhenrypc/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m22.3673429 0c5.3115421 0 9.6326571 4.20898289 9.6326571 9.38235729v13.23528541c0 5.173471-4.321115 9.3823573-9.6326571 9.3823573h-12.73458659c-5.31154208 0-9.63275631-4.2088863-9.63275631-9.3823573v-13.23528541c0-5.1733744 4.32121423-9.38235729 9.63275631-9.38235729zm6.1542582 22.6176427v-13.23528541c0-3.30527047-2.7607757-5.99428114-6.1542582-5.99428114h-12.73458659c-3.39348246 0-6.15425822 2.68901067-6.15425822 5.99428114v13.23528541c0 3.3052705 2.76077576 5.9942811 6.15425822 5.9942811h12.73458659c3.3934825 0 6.1542582-2.6890106 6.1542582-5.9942811zm-12.4595506-15.55045306c2.8144437.08000362 5.1617479 1.01598804 6.7879569 2.7069825 1.5453599 1.60683606 2.33292 3.76055676 2.2776648 6.22810816-.0530727 2.3635853-1.0114577 4.5778885-2.6984772 6.2349684-1.7138039 1.6834579-3.997024 2.6106496-6.4291454 2.6106496-5.0331829 0-9.12801944-3.9883932-9.12801944-8.8908374 0-4.9890183 4.14304844-9.03364589 9.19002034-8.88987126zm4.8150389 8.84465176c.0213283-.9502846-.1574326-2.2954663-1.1300034-3.3067198-.8346809-.867962-2.1517782-1.3515588-3.8090373-1.3987107-.0446406-.0012561-.0889837-.0018358-.1332275-.0018358-1.2343634 0-2.4022618.4933557-3.2887258 1.389145-.8979713.9075774-1.3925896 2.1020276-1.3925896 3.3633408 0 2.6190558 2.1875899 4.7497803 4.8765438 4.7497803 2.6744705 0 4.8168246-2.1061824 4.8770398-4.7949998zm3.747711-10.30122981c1.0847676 0 1.964089.85646391 1.964089 1.9130335 0 1.05647297-.8793214 1.91293688-1.964089 1.91293688-1.0847677 0-1.9640891-.85646391-1.9640891-1.91293688 0-1.05656959.8793214-1.9130335 1.9640891-1.9130335z" /></svg>
      </a>
      {' '}
      <a
        aria-label="Youtube"
        href="https://www.youtube.com/channel/UC8pXatFvdTq2fWs_2cyWgug"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m31.9820274 13.7500274c-.0116164-.6428493-.0624658-1.4555616-.1514521-2.4374795-.0894246-.9823561-.2174246-1.86038352-.384-2.63408215-.1904657-.86882191-.6042739-1.60109589-1.2409863-2.19638356-.6369315-.59528767-1.3779726-.94049315-2.2235616-1.03583561-2.6430685-.29742466-6.6369315-.44624658-11.9820274-.44624658s-9.33917808.14882192-11.9820274.44624658c-.84536986.09534246-1.58356164.44054794-2.21435616 1.03583561-.6310137.59506849-1.04175343 1.32734247-1.23221918 2.19638356-.17841096.77391781-.31254794 1.65194525-.40175342 2.63408215-.08920548.9819179-.13983562 1.794411-.15167124 2.4374795-.01205479.6428493-.0179726 1.5355616-.0179726 2.6785753 0 1.1427946.00591781 2.0357261.0179726 2.6785754.01183562.6430685.06246576 1.4555616.15167124 2.4374794.08920548.9823562.21720548 1.8603836.384 2.6340822.19046575.8690411.60405479 1.6013151 1.2409863 2.1963836.6369315.5950685 1.37819178.9404931 2.22334246 1.0356164 2.64284932.2976439 6.6367123.4464658 11.9820274.4464658 5.3455342 0 9.3389589-.1488219 11.9820274-.4464658.8453699-.0951233 1.5835616-.4405479 2.2145753-1.0356164s1.0415343-1.3275617 1.232-2.1963836c.178411-.7736986.312548-1.6519452.4019726-2.6340822.0889863-.9819178.1396165-1.7946301.1514521-2.4374794.0120548-.6428493.0179726-1.5357808.0179726-2.6785754 0-1.1430137-.0059178-2.035726-.0179726-2.6785753zm-9.6604932 3.6429589-9.1427945 5.7139726c-.1667945.1190137-.369315.178411-.6073424.178411-.178411 0-.3629589-.0475617-.5534247-.142685-.3927671-.2143561-.5891507-.5475068-.5891507-.9998904v-11.4283835c0-.4521644.1963836-.78553429.5891507-.99989045.4048219-.21457534.7916712-.20252055 1.1607671.03572603l9.1427945 5.71419182c.3570411.2020821.5354521.5236164.5354521.9641643 0 .440548-.178411.7620822-.5354521.9643836z" /></svg>
      </a>
      {' '}
      <a
        aria-label="Vimeo"
        href="https://vimeo.com/user109864896"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21.9150307 6.71007513c.149066-.83734593.1453854-1.69769587-.3708246-2.34916941-.723246-.9146394-2.2571534-.94776517-3.310737-.78581695-.8557491.13066277-3.7505736 1.40784536-4.7351452 4.4664584 1.7446241-.13250309 2.6592635.12422165 2.4917943 2.04735683-.0708524.8033-.4766431 1.6838934-.9302822 2.5286007-.5235712.9744498-1.5053823 2.8847027-2.7917665 1.5081428-1.1603222-1.2422165-1.072907-3.6125496-1.3388333-5.19246493-.1481459-.88519427-.303653-1.98938671-.5935035-2.90034547-.2493634-.78397663-.82446365-1.72898132-1.52562585-1.93417708-.7545315-.22083849-1.68481363.12422165-2.23230904.44627777-1.74278373 1.02321833-3.06965497 2.48075234-4.57779781 3.68248178v.11225956c.29905212.28524971.38002623.75361134.81986288.8171024 1.03702073.15274663 2.02619312-.96524822 2.7163134.19783448.41867296.71312429.54933573 1.49250009.81802256 2.25899369.35886254 1.0222982.63583081 2.1356922.93028213 3.3098168.49688659 1.9912271 1.10787308 4.9651854 2.82765283 5.6939524.877833.3717447 2.1973429-.126062 2.8653793-.5235713 1.8090353-1.0692263 3.218721-2.6215369 4.4259714-4.2014522 2.7586408-3.7376914 4.2815062-7.97226939 4.5115462-9.18228027" fillRule="evenodd" /></svg>
      </a>
    </p>
  );
}

SocialIcons.propTypes = {
  className: string,
};

SocialIcons.defaultProps = {
  className: '',
};

export { SocialIcons };
