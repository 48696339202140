import { useLocation } from '@gatsbyjs/reach-router';
import * as styles from './chat-bubble.module.scss';
import chat from '../../assets/icons/new-chat.svg';
import chevronUp from '../../assets/icons/chevron-up.svg';
import { openChatLP } from '../../helpers/misc';

function ChatBubble() {
  const location = useLocation();
  const lPStyle = location.pathname.includes('locations');

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.toTop}
        onClick={() => window.scrollTo(0, 0)}
      >
        <img alt="Scroll to top" src={chevronUp} className={styles.iconChevron} />
      </button>
      {lPStyle
        ? <div id={process.env.GATSBY_CHAT_ID} />
        : (
          <button
            type="button"
            className={styles.chat}
            onClick={() => openChatLP(() => null)}
          >
            <img alt="Chat with us" src={chat} className={styles.iconChat} />
          </button>
        )}

    </div>
  );
}

export { ChatBubble };
