import { useState } from 'react';
import { array, string, bool } from 'prop-types';
import { Link } from 'gatsby';
import * as styles from './header.module.scss';

function Item({
  text, to, subMenu, active,
}) {
  const [seeMenu, setSeeMenu] = useState(false);
  const isWindowDefined = typeof window !== 'undefined';
  return (
    <div className={styles.linkContainer} onMouseLeave={() => setSeeMenu(false)}>

      {!subMenu.length ? (
        <Link to={to} className={styles.linkTextHover} activeClassName={styles.linkTextActive}>
          {text}
        </Link>
      ) : (
        <div className={styles.containerLinkHover}>
          <Link
            onFocus={() => setSeeMenu(true)}
            onMouseOver={() => setSeeMenu(true)}
            type="button"
            to={to}
            className={active ? styles.linkTextActive : styles.linkText}
          >
            {text}
          </Link>
          {seeMenu
          && (
          <div className={styles.menuContainer}>
            <div className={styles.list}>
              {subMenu?.map((item) => (
                <Link
                  to={item.uri}
                  className={isWindowDefined && window.location.pathname === item.uri
                    ? styles.itemActive : styles.item}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
          )}
        </div>
      ) }
    </div>
  );
}

Item.defaultProps = {
  to: '',
  text: '',
  subMenu: [],
  active: false,
};

Item.propTypes = {
  to: string,
  text: string,
  subMenu: array,
  active: bool,
};

export { Item };
