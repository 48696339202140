import './styles.module.scss';
import '@fontsource/lato/900.css';
import '@fontsource/lato/400.css';
import '@fontsource/raleway/100.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/700.css';
import '@fontsource/yellowtail';
import fetch from 'cross-fetch';
import {
  ApolloClient, ApolloProvider, HttpLink, InMemoryCache,
} from '@apollo/react-hooks';

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: process.env.GATSBY_GRAPHQL_SERVER_URL,
  fetch,
});
const client = new ApolloClient({
  link,
  cache,
});

export { wrapPageElement } from './gatsby-shared';

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    {element}
  </ApolloProvider>
);

const portal = document.createElement('div');
portal.id = 'portal';
document.body.appendChild(portal);
