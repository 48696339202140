const currentYear = new Date().getFullYear();

const constants = {
  sizeTypeFormPopUp: 75,
  familyLawCategoryId: 51,
  realEstateCategoryId: 25,
  litigationCategoryId: 2170,
  texasDate: '2023-01-01',
  default2023Author: 'Robinson & Henry, Attorneys At Law',
  default2023AuthorUrl: '/about',
  defaultPre2023Author: 'Bill Henry',
  defaultPre2023AuthorUrl: '/team/attorney-william-l-henry-iv',
  blogPostsSelectedStateKey: 'blogPostStateKey',
  calculatorsIds: {
    coloradoAlimonyCalculator: {
      id: 'coloradoAlimonyCalculator', termsAcceptedKey: 'colorado-alimony-terms-accepted', calculatorFinishedKey: 'colorado-alimony-calculator-finished', calculatorResultsKey: 'colorado-alimony-results',
    },
    texasAlimonyCalculator: {
      id: 'texasAlimonyCalculator', termsAcceptedKey: 'texas-alimony-terms-accepted', calculatorFinishedKey: 'texas-alimony-calculator-finished', calculatorResultsKey: 'texas-alimony-results',
    },
    attorneyPayCalculator: {
      id: 'attorneyPayCalculator', termsAcceptedKey: 'attorney-calculator-terms-accepted', calculatorFinishedKey: 'attorney-calculator-finished',
    },
  },
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
    'October', 'November', 'December'],
  years: Array.from({ length: currentYear - 1959 }, (_, index) => currentYear - index),
};

export { constants };
