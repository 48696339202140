import * as styles from './index.module.scss';
import { Wrap } from '../../sections-wrap';

function TopHeaderBar() {
  return (
    <div className={styles.topBarContainer}>
      <Wrap>
        <span className={styles.text}>
          Start Your Case Assessment Now:
          {' '}
          <a href="tel:3036880944">303-688-0944</a>
        </span>
      </Wrap>
    </div>
  );
}

export { TopHeaderBar };
