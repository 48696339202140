import { useState } from 'react';
import { array, func, string } from 'prop-types';
import { Link } from 'gatsby';
import * as styles from './header.module.scss';

function Item({
  text, to, subMenu, closeMenuFn,
}) {
  const [seeMenu, setSeeMenu] = useState(false);

  return (
    <div className={styles.linkContainer}>

      {!subMenu.length ? (
        <Link onClick={closeMenuFn} to={to} className={styles.item}>
          {text}
        </Link>
      ) : (
        <div>
          <Link onClick={closeMenuFn} to={to} className={styles.item}>
            {text}
          </Link>
          <button className={styles.buttonItem} type="button" onClick={() => setSeeMenu(!seeMenu)}>
            <span className={seeMenu ? styles.expandItemActive : styles.expandItem} />
          </button>
          {seeMenu
          && (
            <div className={styles.list}>
              {subMenu?.map((item) => (
                <Link
                  key={item.uri}
                  onClick={closeMenuFn}
                  to={item.uri}
                  className={styles.item}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          )}
        </div>
      ) }
    </div>
  );
}

Item.defaultProps = {
  to: '',
  text: '',
  subMenu: [],
  closeMenuFn: () => null,
};

Item.propTypes = {
  to: string,
  text: string,
  subMenu: array,
  closeMenuFn: func,
};

export { Item };
